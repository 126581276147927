<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-account-statements"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel" v-if="!loading">
          <b-col v-if="!has_antecipation">
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <p
                    data-anima="bottom"
                    class="dados-campo amount-available"
                    :class="{ mais: statement.balance > 0 }"
                  >
                    {{ statement.balance > 0 ? "+" : "" }}
                    {{ statement.base_currency.currency_symbol }}
                    {{ formatMoney(statement.balance, 2, ",") }}
                  </p>
                </b-col>
              </b-row>

              <b-row class="item-dados" v-if="statement.type != 'SALE' && statement.type !== 'REFUNDED'">
                <b-col class="sumary">
                  <b-row>
                    <b-col cols="12" class="mb-2">
                      <h5 class="titulo-item">{{ $t('shared.text_2151') }}</h5>
                    </b-col>
                  </b-row>  
                  <b-row>
                    <b-col cols="6" class="mb-3">
                      <p class="label-campo">{{ $t('shared.text_2152') }}</p>
                      <p class="dados-campo">#{{ statement.id }}</p>
                    </b-col>
                    <b-col
                      v-if="
                        statement.type == 'WITHDRAW' ||
                          statement.type == 'WITHDRAW_FEE'
                      " 
                      cols="6"
                      class="mb-3"
                    >
                      <p class="label-campo">ID do Saque</p>
                      <p class="dados-campo">{{ statement.withdraw_id }}</p>
                    </b-col>

                    <b-col
                      v-if="
                        statement.type == 'ANTECIPATION' ||
                          statement.type == 'ANTECIPATION_FEE'
                      " 
                      cols="6"
                      class="mb-3"
                    >
                      <p class="label-campo">ID do Saque</p>
                      <p class="dados-campo">{{ statement.id }}</p>
                    </b-col>

                    <b-col cols="6">
                      <p class="label-campo">{{ $t('views.affiliation.text_937') }}</p>
                      <p class="dados-campo">{{ statement.created_at | date }}</p>
                    </b-col>
                    
                    <b-col  cols="6" class="mb-3"
                    >
                      <p class="label-campo">{{ $t('shared.text_2169') }}</p>
                      <p class="dados-campo">{{ (statement.type == 'WITHDRAW') ? statement.created_at : statement.available_date | date }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="item-dados" v-if="statement.type == 'WITHDRAW' || statement.type == 'ANTECIPATION'">
                <b-col class="sumary">
                  <b-row>
                    <b-col cols="12" class="mb-2">
                        <div class="label-campo">{{ $t("shared.text_2137") }}</div>
                    </b-col>
                  </b-row>

                  <b-col>
                    <template v-if="statement.base_currency.currency == 'BRL'">
                      <b-col cols="12" class="mb-2">
                        <div class="grid-participations">
                          <span class="subtitle-item">{{ $t("shared.text_2170") }}</span>
                          <span class="desctitle-item" style="color: #2133d2; float:right">
                              <template v-if="statement.base_currency">
                                {{ statement.base_currency.currency_symbol }}
                              </template>
                                  {{ formatMoney(getValueDraw(statement)) }}
                          </span>
                        </div>
                      </b-col>

                    </template>
                  </b-col>

                  <b-col >
                    <template v-if="statement.base_currency.currency == 'BRL'">
                      <b-col cols="12" class="mb-2">
                        <div class="grid-participations">
                          <span class="subtitle-item">{{ (statement.type == 'WITHDRAW') ? $t("shared.text_2171") :  $t("shared.text_2173")   }}</span>
                          <span class="desctitle-item" style="color: #FF0C37; float:right">
                              <template v-if="statement.base_currency">
                                {{ statement.base_currency.currency_symbol }}
                              </template>
                                  {{ formatMoney(statement.fees.balance) }}
                          </span>
                        </div>
                      </b-col>

                    </template>
                  </b-col>

                  
                  <b-col>
                    <template v-if="statement.base_currency.currency == 'BRL'">
                      <b-col cols="12" class="mb-2">
                        <div class="grid-participations">
                          <span class="subtitle-item">{{ $t("shared.text_2172") }}</span>
                          <span class="desctitle-item" style=" float:right">
                              <template v-if="statement.base_currency">
                                {{ statement.base_currency.currency_symbol }}
                              </template>
                                  {{ formatMoney(statement.balance*-1) }}
                          </span>
                        </div>
                      </b-col>

                    </template>
                  </b-col>
                </b-col>
              </b-row>

              <b-row class="item-dados"></b-row>

                <sale-details 
                  v-if="statement.sale_id && statement.type !== 'AMBASSADOR_COMISSION' && statement.type == 'SALE' || statement.type == 'REFUNDED'"
                  ref="extract-preview"
                  :distribuitions="distribuitions" 
                  :loading="loading" 
                  :id_contract="id_contract" 
                  :sale_id="statement.sale_id"
                  :sale="sale"
                  :openType="openType"
                  :permissions="permissions"
                  :statement_id="statement.id"
                  :statement_created_at="statement.created_at"
                  :statement_type="getType(statement.type)"
                  :statement_available_date="statement.available_date"
                  :statement_withdraw_id="statement.withdraw_id"
                  :sale_balance="statement.balance"
                  @reload="fetchStatement"
                />
              
                <comission-details 
                  v-if="statement.sale_id && statement.type === 'AMBASSADOR_COMISSION'"
                  ref="extract-preview"
                  :loading="loading" 
                  :sale_id="statement.sale_id"
                  :sale="sale"
                  :permissions="permissions" 
                />

              <!-- Product -->
              <!-- <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">Produto</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          :src="statement.product.images[0].path"
                          v-if="
                            !loading &&
                              statement.product &&
                              statement.product.images.length
                          "
                        />
                      </div>
                      <div
                        class="name-product"
                        v-if="!loading && statement.product"
                      >
                        <span>Produto: #{{ statement.product.id }}</span>
                        <p>{{ statement.product.name }}</p>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row> -->

              <!-- Client -->
              <!-- <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">{{ $t('shared.text_2064') }}</h5>

                  <p class="label-campo">
                    <span class="title-item">Nome</span>
                    <span>{{ statement.sale.client.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Endereço de e-mail</span>
                    <span>{{ statement.sale.client.email }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Telefone</span>
                    <span>{{ statement.sale.client.cellphone }}</span>
                  </p>
                </b-col>
              </b-row> -->
            </b-overlay>
          </b-col>
          <b-col v-if="has_antecipation">
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <span>{{ $t('shared.text_2155') }}</span>
                  <p class="dados-campo amount-available mt-3">
                    {{ statement.base_currency.currency_symbol }} {{ formatMoney(antecipation.total, 2, ",") }}
                  </p>
                </b-col>
              </b-row>

              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row>
                    <b-col cols="12" class="mb-2">
                      <h5 class="titulo-item">{{ $t('shared.text_2151') }}</h5>
                    </b-col>
                  </b-row> 
                  <b-row>
                    <b-col>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('shared.text_2152') }}</span>
                        <span>{{ antecipation.id }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('note_center.text_2571') }}</span>
                        <span>{{ antecipation.created_at | datetime }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('shared.text_2154') }}</span>
                        <span>{{ $t('shared.text_2156') }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('shared.text_2157') }}</span>
                        <span>{{ antecipation.updated_at | datetime }}</span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('shared.text_2158') }}</span>
                        <span>
                          {{ statement.base_currency.currency_symbol }} {{ formatMoney(antecipation.fee, 2, ",") }}
                        </span>
                      </p>
                      <p class="label-campo">
                        <span class="title-item">{{ $t('shared.text_2056') }}</span>
                        <span>
                          {{ statement.base_currency.currency_symbol }}
                          {{ formatMoney(antecipation.amount, 2, ",") }}
                        </span>
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">{{ $t('client.text_76') }}</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          :src="statement.product.images[0].path"
                          v-if="
                            !loading &&
                              statement.product &&
                              statement.product.images.length
                          "
                        />
                      </div>
                      <div
                        class="name-product"
                        v-if="!loading && statement.product"
                      >
                        <span>{{ $t('client.text_76') }}: #{{ statement.product.id }}</span>
                        <p>{{ statement.product.name }}</p>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row>

              <!-- Client -->
              <b-row
                class="item-dados"
                v-if="statement.type == 'SALE' || statement.type == 'REFUNDED'"
              >
                <b-col>
                  <h5 class="titulo-item">{{ $t('shared.text_2064') }}</h5>

                  <p class="label-campo">
                    <span class="title-item">{{ $t('shared.text_58') }}</span>
                    <span>{{ statement.sale.client.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">{{ $t('contracts.right_preview.text_1797') }}</span>
                    <span>{{ statement.sale.client.email }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">{{ $t('contracts.right_preview.text_1800') }}</span>
                    <span>{{ statement.sale.client.cellphone }}</span>
                  </p>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
import AccountStatementsService from "@/services/resources/AccountStatementsService";
import AntecipationService from "@/services/resources/AntecipationService";
import SaleService from "@/services/resources/SaleService";
import ContractService from "@/services/resources/ContractService";
import SaleDetails from "@/components/shared/SaleDetails";
import ComissionDetails from "@/components/shared/ComissionDetails";
import Cookies from "js-cookie";
import Money from "@/mixins/money";

const service = AccountStatementsService.build();
const serviceAntecipation = AntecipationService.build();
const serviceSale = SaleService.build();
const serviceContract = ContractService.build();

export default {
  name: "DetalhesExtrato",
  components: {
    SaleDetails,
    ComissionDetails,
  },
  mixins: [Money],
  data() {
    return {
      loading: true,
      antecipation_id: -1,
      statement_id: -1,
      visible: false,
      has_antecipation: false,
      antecipation: null,
      statement: {
        sale_id: null,
        product: {
          name: "",
          description: "",
          images: [{ path: null }],
        },
        sale: {
          client: {
            name: null,
            email: null,
            cellphone: null,
          },
        },
      },
      id_contract: "",
      distribuitions: [],
      sale: {
        avaliation: [],
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        seller: {
          name: null,
          email: null,
          cellphone: null,
        },
        proposal: {
          name: "",
          description: "",
        },
        refund_solicitations: [],
        comission: null,
      },
      openType: null,
      permissions: {},
    };
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
  },
  methods: {
    getValueDraw(statement){
      return (statement.fees.balance + statement.balance) * -1;
    },
    getType(type) {
      let r = "";
      switch (type) {
        case "SALE": {
          r = "Venda";
          break;
        }
        case "AMBASSADOR_COMISSION": {
          r = "Comissão";
          break;
        }
        case "REFUNDED": {
          r = "Reembolso";
          break;
        }
        case "WITHDRAW": {
          r = "Saque";
          break;
        }
        case "WITHDRAW_FEE": {
          r = "Taxa de saque";
          break;
        }
        case "ANTECIPATION": {
          r = "Antecipação";
          break;
        }
        case "ANTECIPATION_FEE": {
          r = "Taxa de antecipação";
          break;
        }
        case "ANTECIPATION_BALANCE": {
          r = "Saldo da antecipação";
          break;
        }
        case "ANTECIPATION_REMAINING": {
          r = "Antecipação restante";
          break;
        }
      }
      return r;
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          this.permissions = dados.reduce((obj, item) => {
            obj[item.name] = true;
            return obj;
          }, {});
        }
      }
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
    openPanel(id, antecipation) {
      this.getPermissions();
      this.openType = "sale";
      this.visible = true;
      this.has_antecipation = false;
      if (!antecipation) {
        this.statement_id = id;
        this.fetchStatement();
      } else {
        this.antecipation_id = id;
        this.fetchAntecipation();
      }
    },
    fetchAntecipation() {
      this.has_antecipation = true;
      this.antecipation = {};
      this.statement = {
        product: {
          name: "",
          description: "",
          images: [{ path: null }],
        },
      };

      this.loading = true;

      let data = {
        id: this.antecipation_id,
      };

      serviceAntecipation
        .read(data)
        .then((response) => {
          if (response.id) {
            this.antecipation = response;
          } else {
            this.visible = false;
            this.$bvToast.toast("Antecipação não encontrada", {
              title: "Antecipação",
              variant: "info",
              autoHideDelay: 9000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchStatement() {
      this.has_antecipation = false;
      this.statement = {
        product: {
          name: "",
          description: "",
          images: [{ path: null }],
        },
      };

      this.loading = true;

      let data = {
        id: this.statement_id,
      };

      service
        .read(data)
        .then((response) => {
          this.statement = response;
          if(response.sale_id) { 
            this.fetchSale(response.balance); 
          } else this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        })
    },
    fetchContract() {
      let data = {
        id:
          "0?contract_id=" +
          this.sale.contract_id +
          "&client_id=" +
          this.sale.client_id,
      };

      serviceContract
        .read(data)
        .then((response) => {
          this.id_contract = response.id || null;
        })
        .catch((err) => {
          console.log(err);
          this.id_contract = null;
        });
    },
    fetchSale(balance) {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        seller: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
        comission: null,
      };

      this.loading = true;

      let data = {
        id: this.statement.sale_id,
      };

      serviceSale
        .read(data)
        .then((response) => {
          this.sale = response;
          this.sale.comission = balance;
          this.distribuitions = response.distribuition
            ? response.distribuition
            : [];

          if (this.sale.type === "SUBSCRIPTION") {
            this.fetchContract();
          }

          if (this.sale.shipping_selected) {
            this.sale.shipping_selected = JSON.parse(
              this.sale.shipping_selected
            ).frete;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.panel {
  .sumary {
    font-size: 13px;
    // padding-top: 20px;
    // padding-bottom: 20px;
  }

  .product-main {
    display: grid;
    grid-template-columns: 0.5fr 3fr;
    gap: 20px;
    align-items: center;
  }
  .img-product {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-product,
  .img-product img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ededf0;
    object-fit: cover;
  }
  .name-product span {
    font-size: 11px;
    color: #81858e;
  }
  .name-product p {
    font-weight: 600;
  }
}
.container-sidebar {
  padding: 30px;
  padding-top: 20px;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 12px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}
.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 10px;
}
.amount-available {
  font-size: 32px;
  font-weight: 600;
  color: #333;
  padding-bottom: 10px;
}
.amount-available.mais {
  color: #002363;
}
</style>
