<template>
  <b-container class="container-table">
    <!-- Header -->
    <div class="header-table d-flex">
      <div class="d-flex">
        <!-- Filter -->
        <div class="d-flex">
          <!-- Filter Button -->
          <div class="btns-flex" @click="openFilters">
            <b-button class="btn-table" title="Filtros">
              <img src="@/assets/img/icons/filtro.svg"/>
              <span class="filter">Filtros</span>
            </b-button>
       
          </div>
          <!-- Feedback Menssage -->
          <div v-if="filterAntecipation" class="ml-5 limpa-filtro">
            <span>
              Estamos listando as transações ref. a antecipação selecionada
              anteriormente. Se desejar listar todas as transações
              <a href="#" @click.prevent="resetAntecipation">clique aqui.</a>
            </span>
          </div>
        </div>
      </div>
      <!-- Search -->
      <div class="inputSearch">
        <img src="@/assets/img/icons/search.svg" class="Pointer search" />
        <input
          v-model="search"
          v-on:input="debounce"
          type="text"
          placeholder="Nome do Produto"
          class="input-busca"
        />
      </div>
    </div>

    <!-- Table Header -->
    <b-row class="Table-header mb-1">
      <b-col cols="2" class="d-none d-md-block">{{ $t("views.seller.statements.text_2800") }} </b-col>
      <b-col cols="2" md="2">{{ $t("views.seller.statements.text_2801") }}</b-col>
      <b-col cols="1" md="1">{{ $t("views.seller.statements.text_2806") }}</b-col>
      <b-col cols="2" md="2" class="d-none d-md-block">{{ $t("views.seller.statements.text_2802") }}</b-col>
      <b-col cols="2" md="2" class="">{{ $t("views.seller.statements.text_2803") }}</b-col>
      <b-col cols="2" class="d-none d-md-block">{{ $t("views.seller.statements.text_2804") }}</b-col>
      <b-col cols="1" md="1" class="">{{ $t("views.seller.statements.text_2805") }}</b-col>
    </b-row>

    <!-- Table Body -->
    <template v-if="!loading && statements.length > 0">
      <b-row
        class="Table-body"
        v-for="item in statements"
        :key="item.id"
        :class="{
          'is-green': item.type == 'SALE',
          'is-light-blue':
            item.type === 'WITHDRAW' ||
            item.type === 'WITHDRAW_FEE' ||
            item.type === 'ANTECIPATION' ||
            item.type === 'ANTECIPATION_FEE' ||
            item.type === 'ANTECIPATION_BALANCE' ||
            item.type === 'ANTECIPATION_REMAINING' ||
            item.type === 'AMBASSADOR_COMISSION',

          'is-red': 
            item.type === 'CHARGEBACK' || 
            item.type === 'REFUNDED' ||
            item.type === 'AMBASSADOR_COMISSION_REFUNDED' ||
            item.type === 'AMBASSADOR_COMISSION_CHARGEBACK',
        }"
      >
        <!-- Creation Date -->
        <b-col cols="2" class="d-none d-md-block">
          {{ item.created_at | datetime }}
        </b-col>
        <!-- Type -->
        <b-col cols="2" md="2">
          <!-- Sale -->
          <span v-if="item.type === 'SALE'">
            Venda
          </span>
          <!-- Comission -->
          <span v-if="item.type === 'AMBASSADOR_COMISSION'">
            Comissão
          </span>
          <!-- Chargeback -->
          <span v-if="item.type === 'CHARGEBACK'">
            Chargeback
          </span>
          <!-- Refund -->
          <span v-if="item.type === 'REFUNDED'">
            Reembolso
          </span>
          <!-- Reemb. Comissão -->
          <span v-if="item.type === 'AMBASSADOR_COMISSION_REFUNDED' || item.type === 'AMBASSADOR_COMISSION_CHARGEBACK'">
            Reemb. Comissão
            <a href="#"
              class="link-venda"
              @click.prevent="openComission(item.sale_id)"
            >
              ({{ item.sale_id }})
            </a>
          </span>
          <!-- Withdraw -->
          <span v-if="item.type === 'WITHDRAW'">
            Saque
          </span>
          <!-- Withdraw Fee -->
          <span v-if="item.type === 'WITHDRAW_FEE'">
            Taxa de saque
          </span>
          <!-- Anticipation Fee -->
          <span v-if="item.type === 'ANTECIPATION_FEE'">
            Taxa de Antecipação
          </span>
          <!-- Anticipation Balance -->
          <span v-if="item.type === 'ANTECIPATION_BALANCE'">
            Saldo da Antecipação
          </span>
          <!-- Anticipation Remaining -->
          <span v-if="item.type === 'ANTECIPATION_REMAINING'">
            Antecipação Restante
          </span>
          <!-- Anticipation -->
          <span v-if="item.type === 'ANTECIPATION'">
            Antecipação
          </span>
          <!-- Shipping -->
          <span v-if="item.type === 'SHIPPING'">
            Envio
          </span>
          <!-- Conversion Fee -->
          <span v-if="item.type === 'CURRENCY_CONVERSION_FEE'">
            Taxa de Conversão
          </span>
        </b-col>
        <!-- ID venda -->
        <b-col cols="1" class="d-none d-md-block">
          {{ item.sale_id }}
        </b-col>
        <!-- Product -->
        <b-col cols="2" class="d-none d-md-block">
          <template v-if="!item.proposal">
            <div v-if="item.type != 'WITHDRAW' && item.type != 'WITHDRAW_FEE'"
              class="Table-body-title"
            >
              {{
                item.product
                  ? item.product.name.length > maxLength
                    ? item.product.name.substr(0, maxLength) + ".."
                    : item.product.name
                  : ""
              }}
            </div>
          </template>
          <template v-else>
            <div
              class="Table-body-title"
              v-if="
                item.type != 'WITHDRAW' && item.type != 'WITHDRAW_FEE'
              "
            >
              {{
                item.proposal.name.length > maxLength
                  ? item.proposal.name.substr(0, maxLength) + ".."
                  : item.proposal.name
              }}
            </div>
          </template>
        </b-col>
        <!-- Value -->
        <b-col cols="2" md="2" class="">
          <span class="flex" v-if="item.sale">
            <font-awesome-icon  
              v-if="item.sale.method != 'TWO_CREDIT_CARDS' && item.sale.local_currency_id == 1"
              :icon="getIconMethod(item.sale.method, item.sale.local_currency_id)"
              class="icon-gray"
              :id="`method-icon-${item.sale.id}`"
            />
            <div 
              v-else-if="item.sale.method != 'TWO_CREDIT_CARDS' && item.sale.local_currency_id != 1"
              :id="`method-icon-${item.sale.id}`"
              class="Table-icon-international"
            >
              <img src="@/assets/img/icons/international.svg" />
            </div>
            <b-tooltip
              :target="`method-icon-${item.sale.id}`"
              :title="getMethod(item.sale.method, item.sale.local_currency_id)"
              placement="left"
            />
            <!-- Two Credit Cards -->
            <font-awesome-icon
              v-if="item.sale.method == 'TWO_CREDIT_CARDS'"
              icon="credit-card"
              class="icon-gray"
              :id="`method-icon-${item.sale.id}`"
            />
            <font-awesome-icon
              v-if="item.sale.method == 'TWO_CREDIT_CARDS'"
              icon="credit-card"
              class="icon-gray TWO_CREDIT_CARDS"
            />
            <!-- Bump -->
            <font-awesome-icon
              v-if="item.sale.bump_id"
              icon="donate"
              class="icon-gray ml-2"
              :id="`bump-sale-${item.id}`"
            />
            <b-tooltip
              v-if="item.sale.bump_id"
              :target="`bump-sale-${item.sale.id}`"
              title="ORDER BUMP"
              placement="left"
            />
            <!-- Coupom -->
            <font-awesome-icon
              v-if="item.sale.coupon_id"
              icon="receipt"
              class="icon-gray ml-2"
              :id="`cupom-sale-${item.sale.id}`"
            />
            <b-tooltip
              v-if="item.sale.coupon_id"
              :target="`cupom-sale-${item.sale.id}`"
              :title="$t('views.seller.sales.text_1425')"
              placement="left"
            />
          </span>

          <span  class="valor-venda">
            {{ currency.currency_symbol }}
            {{ formatMoney(item.balance, 2, ",") }}
          </span>
        </b-col>
        <!-- Available -->
        <b-col cols="2" class="d-none d-md-block">
          <span v-if="item.type !== 'WITHDRAW' && item.type !== 'WITHDRAW_FEE'">
            {{ formatDate(item.available_date) }}
          </span>
        </b-col>
        <!-- Actions -->
        <b-col cols="1" md="1">
          <div class="Table-body-action" @click="openPanel(item.id)">
            <img src="@/assets/img/icons/eye.svg" />
          </div>
        </b-col>
      </b-row>
    </template>

    <Paginate v-if="statements.length > 0"
      :activePage="pagination.currentPage"
      :itemsPerPage="pagination.perPage"
      :totalPages="pagination.totalPages"
      @to-page="toPage"
      @per-page="perPage"
    />

    <!-- Loading -->
    <div v-if="loading && statements.length == 0"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Feedback Menssage -->
    <b-row v-if="!loading && statements.length == 0"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">Nenhum extrato encontrado</p>
    </b-row>

    <Filters @filter="fetchStatements" />
  </b-container>
</template>

<script>
import Paginate from "@/components/shared/Paginate";
import Money from "@/mixins/money";
import Filters from "@/components/Seller/Statements/modals/filters";
import _ from "lodash";
import { textMethod } from "@/helpers.js";
import { iconMethod } from "@/helpers.js";

import AccountStatementsService from "@/services/resources/AccountStatementsService";

const serviceStatement = AccountStatementsService.build();

export default {
  name: "TableTransactions",
  components: {
    Paginate,
    Filters
  },
  mixins: [Money],
  props: {
    tabIndex: {
      required: true,
      type: Number,
      default: 0
    },
    updateStatements: {
      required: true,
      type: Boolean,
      default: false
    },
    filterStatements: {
      required: true,
      type: Object,
      default: () => ({})
    },
    currency: {
      required: true,
      type: Object,
      default: () => ({
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      })
    }
  },
  watch: {
    updateStatements() {
      if(this.updateStatements && this.tabIndex === 0) {
        this.pagination.currentPage = 1
        this.pagination.perPage = 10
        this.filterAntecipation = false
        this.fetchStatements()
        this.$emit('reset-update-statements')
      }
    },
    filterStatements() {
      if(this.filterStatements.antecipation_group_id) {
        this.filterAntecipation = true
        this.fetchStatements(this.filterStatements)
      }
    }
  },
  data() {
    return {
      antecipation: 0,
      antecipation_id: -1,
      available: 0,
      block: 0,
      filterAntecipation: false,
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      search: "",
      statements: [],
      statement_id: -1,
      total: 0,
    }
  },
  computed: {
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 30;
    }
  },
  methods: {
    getIconMethod(method, currency_id) {
      return iconMethod(method, currency_id);
    },
    getMethod(method, currency_id) {
      return textMethod(method, currency_id);
    },
    formatDate(date) {
      if(date != null) {
        return new Date(date + 'T00:00:01').toLocaleDateString("pt-BR");
      }

      return "";
    },
    resetAntecipation() {
      this.filterAntecipation = false
      this.fetchStatements();
    },
    openSale(id) {
      this.$router.push(`/vendas/?s_id=${id}`);
    },
    openComission(id) {
      this.$router.push(`/comissoes?s_id=${id}`);
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.pagination.perPage = 10;
      this.fetchStatements({}, this.search);
    }, 500),

    openPanel(id, antecipation) {
      if (!antecipation) {
        this.statement_id = id;
        this.$router.push({ query: { ex_id: this.statement_id } });
      } else {
        this.antecipation_id = id;
        this.$router.push({ query: { antecipation_id: this.antecipation_id } });
      }

      this.$emit('open-right-preview', id, antecipation)
    },
    openFilters() {
      this.$bvModal.show("account-statements-filters");
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchStatements();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchStatements();
    },
    fetchStatements(filters = {}, search = "") {
      this.$emit('fetch-last-antecipation');
      this.loading = true;
      this.statements = [];

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        currency_id: this.currency.id
      };
      this.pagination.totalPages = 1;

      if (search.length > 0) {
        data.search = search;
        data.page = this.pagination.currentPage;
        data.per_page = this.pagination.perPage;
      } else {
        if (typeof filters.date_in != "undefined" && filters.date_in != null)
          data.date_in = filters.date_in
        if (typeof filters.date_out != "undefined" && filters.date_out != null)
          data.date_out = filters.date_out
        if (filters.antecipation_group_id) {
          data.antecipation_group_id = filters.antecipation_group_id
        }
      }

      serviceStatement
        .search(data)
        .then(response => {
          this.statements = response.data
          this.pagination.totalPages = response.last_page
          this.$emit('fetch-reports', this.currency.id)
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    }
  },
  mounted() {
    this.fetchStatements()
  }
}
</script>

<style scoped>

.valor-venda {
  margin-left: 10px;
}

.Table-header{
  color: #091E3E
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.btns-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: grid;
    grid-template-columns: 50px 1fr;
  }
}
.btn-table {
  width: max-content;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #F5F5F5;
  padding: 16px;
  outline: none;
}
.btn-table span {
  color: #091E3E;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child img {
  filter: invert(0);
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0px !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
@media screen and (max-width: 768px) {
  .input-busca {
    width: 100%;
  }
}
.search {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
}
.inputSearch {
  position: relative;
}
.filter {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .filter {
    display: none;
  }
  .Table-body {
    padding: 15px;
  }

  header {
    display: block;
  }
  .item-valor {
    text-align: left;
    margin: 0 !important;
    max-width: 270px;
  }
  .item-valor + .item-valor {
    margin-top: 15px !important;
  }
  .item-valor p {
    font-size: 18px;
  }
}
.link-venda {
  font-size: 12px;
  color: #2133d2;
  font-weight: 600;
}
.limpa-filtro {
  font-size: 13px;
  max-width: 60%;
}
.limpa-filtro span {
  color: #81858e;
}
.limpa-filtro a {
  color: #2133d2;
}
</style>
